import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { usePrismicPreview } from 'gatsby-source-prismic'
import { linkResolver } from '../prismic/utils/link-resolver'

const PreviewPage = () => {
  // const linkResolver = ({ node, key, value }) => post => `/`
  const { previewData, path } = usePrismicPreview({
    repositoryName: 'elena-venuti-psicologa',
    linkResolver: () => linkResolver,
  })

  useEffect(() => {
    if (previewData && path) {
      window.__PRISMIC_PREVIEW_DATA__ = previewData
      navigate(path)
    }
  }, [previewData, path])

  return (
    <div
      style={{
        position: 'fixed',
        top: '10px',
        left: '10px',
        backgroundColor: 'rgba(0,0,0,0.8)',
        color: '#FFFFFF',
        zIndex: 9999,
        padding: '0.5em 0.75em',
        fontSize: '20px',
        fontFamily: 'Helvetica, Arial, sans-serif',
        borderRadius: '6px',
      }}
    >
      Loading preview...
    </div>
  )
}

export default PreviewPage
